import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment.staging';
import { Observable, take } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { PkceService } from '../pkce-service.service';
import { StateService } from '../state.service';

export enum ThreeShapeRedirectEnv {
  Prod = 'prod',
  Staging = 'staging'
}

export enum ThreeShapeLocalStorageKey {
  CodeVerifier = 'three_shape_code_verifier',
  AccessToken = 'three_shape_accessToken',
  RefreshToken = 'three_shape_refreshToken'
}


export interface ThreeShapeOauthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}
@Injectable({
  providedIn: 'root'
})
export class ThreeShapeOauthService {
  private authTokenEndpoint = `${environment.threeShape.baseUrl}/connect/token`;

  private currentEnv = environment.production ? ThreeShapeRedirectEnv.Prod : ThreeShapeRedirectEnv.Staging;

  private config = {
    [ThreeShapeRedirectEnv.Prod]: {
      clientId: 'Qualylab.Production',
      scope: 'openid api offline_access communicate.connections.manage data.companies.read_only data.users.read_only',
      codeChallenge: '',
      codeChallengeMethod: 'S256',
      responseMode: 'query',
      baseUrl: environment.threeShape.baseUrl,
      authBaseUrl: `${environment.threeShape.baseUrl}/connect/authorize`,
      redirectUri: `${environment.apiOrigin}/oauth/connect/3shape/callback`,
      allowedOrigin: `${environment.apiOrigin}`
    },
    [ThreeShapeRedirectEnv.Staging]: {
      clientId: 'Qualylab.Staging',
      scope: 'openid api offline_access communicate.connections.manage data.companies.read_only data.users.read_only',
      codeChallenge: '',
      codeChallengeMethod: 'S256',
      responseMode: 'query',
      baseUrl: environment.threeShape.baseUrl,
      authBaseUrl: `${environment.threeShape.baseUrl}/connect/authorize`,
      redirectUri: `${environment.apiOrigin}/oauth/connect/3shape/callback`,
      allowedOrigin: `${environment.apiOrigin}`
      }
  };


  public codeVerifier: any;
  
  constructor(private http: HttpClient, private platform: Platform, private pkceService: PkceService,
    private stateService: StateService
  ) { }


  async getAuthorizationUrl(): Promise<string> {
    const redirectUri = this.config[this.currentEnv].redirectUri;
    if (!this.getCodeVerifier()) {
      this.generateCodeVerifier();
    }
    const codeChallenge = await this.getCodeChallange();

    const authrozitaionUrl = `${this.config[this.currentEnv].authBaseUrl}?client_id=${this.config[this.currentEnv].clientId}` +
      `&response_type=code&scope=${this.config[this.currentEnv].scope}` +
      `&redirect_uri=${redirectUri}` +
      `&code_challenge=${codeChallenge}&code_challenge_method=${this.config[this.currentEnv].codeChallengeMethod}` +
      `&response_mode=${this.config[this.currentEnv].responseMode}`;
    return authrozitaionUrl;
  }



  async login() {
    const authUrl = await this.getAuthorizationUrl();
    const authWindow = window.open(authUrl, '_blank', 'width=800,height=600');

    if (!authWindow) return;

    const messageListener = (event: MessageEvent) => {
      if (event.origin !== this.config[this.currentEnv].allowedOrigin) {
        console.warn("Received OAuth message from an untrusted origin:", event.origin);
        return;
      }

      this.exchangeAuthCodeForToken(event.data.code);
      window.removeEventListener('message', messageListener);
      if (authWindow) authWindow.close();
    };

    window.addEventListener('message', messageListener);
  }


  exchangeAuthCodeForToken(code: string): void {
    const body = new URLSearchParams({
      code,
      client_id: this.config[this.currentEnv].clientId,
      grant_type: 'authorization_code',
      redirect_uri: this.config[this.currentEnv].redirectUri,
      code_verifier: this.getCodeVerifier() || ''
    });

    const headers = new HttpHeaders({
      "Authorization": "No Auth", 
      "Content-Type": "application/x-www-form-urlencoded",
      "skip": "true"
    });

    this.http.post<ThreeShapeOauthResponse>(this.authTokenEndpoint, body, { headers }).pipe(take(1)).subscribe({
      next: (response: ThreeShapeOauthResponse) => {
        console.log("Received OAuth response:", response);
        this.setToken(response.access_token);
        this.setRefreshToken(response.refresh_token);
        this.stateService.setState('threeShapeSignedIn', true);
      },
      error: (error) => {
        console.error("Error exchanging OAuth code for token:", error);
      },
      complete: () => {
        console.log("OAuth exchange complete");
      }
    });
  }



  
    public generateCodeVerifier(): string | null {
      this.codeVerifier = this.pkceService.generateCodeVerifier();
      if(this.platform.isBrowser){
        sessionStorage.setItem(ThreeShapeLocalStorageKey.CodeVerifier, this.codeVerifier);
      }
      return this.codeVerifier;
    }
  
    public clearCodeVerifier(): void {
      if(this.platform.isBrowser){
      sessionStorage.removeItem(ThreeShapeLocalStorageKey.CodeVerifier);
      }
      this.codeVerifier = undefined;
    }
  
    public getCodeVerifier(){
      if(this.platform.isBrowser){
      return sessionStorage.getItem(ThreeShapeLocalStorageKey.CodeVerifier);
      } else {
        return this.codeVerifier;
      }
    }
  
    public async getCodeChallange(): Promise<string | null> {
      const codeChallenge = await this.pkceService.generateCodeChallenge(this.getCodeVerifier());
      return codeChallenge;
    }



    public refreshTokenStatic(refreshToken: any): Observable<ThreeShapeOauthResponse> {
      const body = new URLSearchParams({
        client_id: this.config[this.currentEnv].clientId,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      });
  
      const headers = new HttpHeaders({
        "Authorization": "No Auth", 
        "Content-Type": "application/x-www-form-urlencoded",
        "skip": "true"
      });

      return this.http.post<ThreeShapeOauthResponse>(this.authTokenEndpoint, body, { headers });
  }

  public checkIfTokenExpired(): boolean {

    if (this.platform.isBrowser) {
      const token = localStorage.getItem(ThreeShapeLocalStorageKey.AccessToken);
      if (!token) {
        return false;
      }
      return !this.tokenExpired(token);
    } else {
      return false;
    }
  }

  public isAuthenticated(): boolean | any {
    if (this.platform.isBrowser) {
    
      const token = localStorage.getItem(ThreeShapeLocalStorageKey.AccessToken);
      if (!token) {
      //  this.authMe = null;
        return false;
      } else {
        return true;
      }
    } else {
     // this.authMe = null;
      return false;
    }

  }


  public tokenExpired(token: string | null):any {
   
    if(this.platform.isBrowser){
      if(token){
        try {
          const expiry = JSON.parse(window.atob(token.split('.')[1])).exp;
          return Math.floor(new Date().getTime() / 1000) >= expiry;
        } catch {
          this.logout();
        }
      } else {
        return false;
      }
    
    } else {
      return false;
    }
  }

  public getToken(): string | null {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(ThreeShapeLocalStorageKey.AccessToken);
    } else {
      return null;
    }
  }

  public setToken(val: string) {
    if (typeof window !== 'undefined') {
      localStorage.setItem(ThreeShapeLocalStorageKey.AccessToken, val);
    }
  }

  public setRefreshToken(val: string) {
    if (this.platform.isBrowser) {
      localStorage.setItem(ThreeShapeLocalStorageKey.RefreshToken, val);
    }
  }

  public getRefreshToken(): string | null {
    if (this.platform.isBrowser) {
      const token = localStorage.getItem(ThreeShapeLocalStorageKey.RefreshToken);
      if (!token) {
        return null;
      }

      // token can not be expired so now comment those :
      /*
      if (!this.tokenExpired(token) == false) {
        this.logout();
        this.authMe = null;
        if (this.platform.isBrowser) {
          // DO SOMETHING AFTER SESSION EXPIRED AND LOGGING OUT
        }
        this.messageService.add({ severity: 'warning', key:'notifications', summary: 'Session expired', detail: 'Please log in again' });
      }
      */
      return token;
    } else {
    return null;
    }
  }


   public logout() {
   
      if (typeof window !== 'undefined') {
        
        window.localStorage.removeItem(ThreeShapeLocalStorageKey.AccessToken);
        window.localStorage.removeItem(ThreeShapeLocalStorageKey.RefreshToken);
      }
  
    //  this.router.navigate(['/auth/login'])
      this.stateService.setState('threeShapeSignedIn', false);
    //  this.authMe = null;
      
    }

}
