// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  staging: true,
  defaultLocale: 'en',
  apiOrigin: 'https://api.dev.qualylab.lt',
  apiPath: 'api',
  localeStorageName: 'locale',
  localUrl: 'https://admin.dev.qualylab.lt',
  mainAdminIds: [87],
  threeShape: {
    baseUrl: 'https://staging-identity.3shape.com',
    communicateUrl: 'https://staging-portal.3shapecommunicate.com'
  },
  firebaseConfig: {
    apiKey: "AIzaSyDN_rgEWFdT8y-pysL4vI9n7xhrKQ8RI48",
    authDomain: "qualylab-development.firebaseapp.com",
    projectId: "qualylab-development",
    storageBucket: "qualylab-development.appspot.com",
    messagingSenderId: "84384475301",
    appId: "1:84384475301:web:8ffcfcb7af044dbe47854c"
    },
    vapidKey: "BJopbIdJkuet9Nw6pT7GLxS4cSy_9C1_viEqXYq_42z2_E2v_x_9i5uwHMdybhozH8yffLCz18P30PGM3F4brEw"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


